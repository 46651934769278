<template>
  <TransitionRoot as="template"
                  :show="isPopupOpen"
  >
    <Dialog as="div"
            @close="closePopup"
            class="campaign-popup fixed inset-0 flex items-end justify-center z-40"
            data-lenis-prevent
    >
      <!-- Backdrop -->
      <TransitionChild as="template"
                       enter="transition duration-300 ease-out"
                       enter-from="opacity-0"
                       enter-to="opacity-100"
                       leave="transition duration-200 ease-in"
                       leave-from="opacity-100"
                       leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black/30"
             aria-hidden="true"
        />
      </TransitionChild>

      <!-- Popup -->
      <TransitionChild as="template"
                       enter="transition duration-300 ease-out"
                       enter-from="translate-y-full"
                       enter-to="translate-y-0"
                       leave="transition duration-200 ease-in"
                       leave-to="translate-y-full"
      >
        <DialogPanel class="absolute w-full md:max-w-[73.375vw] rounded-t-[5vw] md:rounded-t-[1.25vw]"
                     :class="popup.type === 'video' ? 'max-h-[150vw] md:max-h-[31.25vw]' : 'h-full max-h-[115vw] md:max-h-[25vw]'"
                     @touchstart="swipeStart"
                     @touchmove="swipeMove"
                     @touchend="swipeEnd"
                     :style="popupStyle"
        >
          <CampaignCloseButton class="z-10"
                               @click="closePopup"
          />
          <CampaignPopupContent class="z-0"
                                :campaign-name="campaign.name"
                                :creative="popup.creative"
          />
        </DialogPanel>
      </TransitionChild>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
import { ref, onMounted } from 'vue';
import { Dialog, DialogPanel, TransitionRoot, TransitionChild } from '@headlessui/vue';
import { useNuxtApp } from '#imports';
import { campaign } from '~/data/constants';

type PopupType = 'image' | 'video'
type Popup = {
  type: PopupType;
  creative: string;
  expiry: number;
  creativesUsed: Array<string>;
}

interface SetPopupParameters {
  type?: PopupType;
  creative: string;
  expiresAfterMinutes: number;
  creativesUsed?: Array<string>;
}

const popup = ref<Popup>();
const isPopupOpen = ref(false);

const { $event } = useNuxtApp();
const trackEvent = $event;

const touchStart = ref<number>();
const touchEnd = ref<number>();
const popupStyle = ref({
  transition: '',
  bottom: '0px'
});

let isLastCreative = false;

function openPopup() {
  if (popup.value) {
    isLastCreative = popup.value.creativesUsed?.length + 1 >= campaign.creatives.length;
    const creative = getRandomCreative(getUnusedCreatives());
    setPopup({
      creative: creative,
      expiresAfterMinutes: isLastCreative ? 30 : 0.5,
      creativesUsed: isLastCreative ? [] : [ ...popup.value.creativesUsed, creative ]
    });
  } else {
    const isOnlyCreative = campaign.creatives.length === 1;
    const creative = getRandomCreative(campaign.creatives);
    setPopup({
      creative: creative,
      expiresAfterMinutes: isOnlyCreative ? 30 : 0.5,
      creativesUsed: isOnlyCreative ? [] : [ creative ]
    });
  }

  trackEvent('impression', { props: { position: 'pop-up', creative: `${campaign.name} | ${popup.value.creative}` } });
  isPopupOpen.value = true;
}

function closePopup() {
  isPopupOpen.value = false;

  if(!isLastCreative){
    setTimeout(() => {
      openPopup();
    }, 30000);
  }
}

function swipeReset() {
  touchStart.value = null;
  touchEnd.value = null;
  popupStyle.value.transition = 'bottom 0.2s';
  popupStyle.value.bottom = '0px';

  setTimeout(() => {
    popupStyle.value.transition = '';
  }, 200);
}

function swipeStart(event: { targetTouches: { clientY: number; }[]; }) {
  touchStart.value = event.targetTouches[0].clientY;
}

function swipeMove(event: { targetTouches: { clientY: number; }[]; }) {
  touchEnd.value = event.targetTouches[0].clientY;

  if (touchEnd.value > touchStart.value) popupStyle.value.bottom = `${touchStart.value - touchEnd.value}px`;
  else popupStyle.value.bottom = '0px';
}

function swipeEnd() {
  if (touchEnd.value - touchStart.value > 100) {
    closePopup();
  } else {
    swipeReset();
  }
}

function setPopup({ type = 'image', creative, expiresAfterMinutes, creativesUsed }: SetPopupParameters) {
  popup.value = {
    type,
    creative,
    expiry: Date.now() + expiresAfterMinutes * 60_000,
    creativesUsed
  };

  localStorage.setItem('activeCampaignPopup', JSON.stringify(popup.value));
}

function getRandomCreative(creatives: Array<string>) {
  return creatives[Math.floor(Math.random() * creatives.length)];
}

function getUnusedCreatives() {
  return campaign.creatives.filter(creative => !popup.value.creativesUsed.includes(creative));
}

function isLocalStorageAvailable(){
  const test = 'test';
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch(e) {
    return false;
  }
}

onMounted(() => {
  if(isLocalStorageAvailable()) {
    popup.value = JSON.parse(localStorage.getItem('activeCampaignPopup'));

    if (popup.value?.expiry > Date.now()) return;
  
    setTimeout(openPopup, 5000);
  } else {
    trackEvent('error', { props: { type: 'storage unavailable' } });
  }
});
</script>
