<template>
  <div class="relative flex justify-center w-full h-full rounded-t-[5vw] md:rounded-t-[1.25vw]"> 
    <NuxtImg v-if="width < 768"
             format="auto"
             :src="mobileBackground"
             :alt="mobileAlt"
             class="absolute md:hidden w-full bottom-0"
             sizes="xs:100vw sm:100vw"
    />
    <NuxtImg v-else
             format="auto"
             :src="desktopBackground"
             :alt="desktopAlt"
             class="absolute max-md:hidden w-full bottom-0"
             sizes="md:74vw lg:74vw xl:74vw xxl:74vw"
    />
    <a class="floater-link absolute"
       :class="buttonClasses"
       :href="buttonLink"
       @click="trackEvent('click', { props: { campaign: campaignName, position: `pop-up | ${creative}` } })"
    >
      <NuxtImg format="auto"
               class="w-full"
               :src="buttonSrc"
               :alt="buttonAlt"
               :sizes="buttonSizes"
      />
    </a>
  </div>
</template>

<script setup lang="ts">
import { useNuxtApp } from '#imports';
import { useWindowSize } from '@vueuse/core';
import { CampaignCreative } from '~/data/constants';

const props = defineProps<{
  campaignName: string;
  creative: string
}>();

const { width } = useWindowSize();

const { $event: trackEvent } = useNuxtApp();

// These can be changed based on creative
let mobileBackground: string;
let mobileAlt: string;
let desktopBackground: string;
let desktopAlt: string;
let buttonClasses: Array<string>;
let buttonSrc: string;
let buttonLink: string;
let buttonAlt: string;
let buttonSizes: string;

if (props.creative === CampaignCreative.MTT2) {
  mobileBackground = 'https://cdn.outfit7.com/web/campaigns/summer-sports/mtt2/popup/bg-mobile-2.png';
  mobileAlt = 'VISIT TOM’S CINEMA';
  desktopBackground = 'https://cdn.outfit7.com/web/campaigns/summer-sports/mtt2/popup/bg-desktop.png';
  desktopAlt = 'VISIT TOM’S CINEMA';
  buttonClasses = ['bottom-[2.5vw]', 'md:bottom-[1vw]', 'md:-left-[2.6vw]', 'w-[93vw]', 'md:w-[30vw]'];
  buttonSrc = 'https://cdn.outfit7.com/web/campaigns/summer-sports/mtt2/popup/cheer-loud.png';
  buttonLink = 'https://o7n.co/mkt-sos-mtt2-ttfweb-popup';
  buttonAlt = 'CHEER LOUD';
  buttonSizes = 'xs:93vw sm:93vw md:30vw lg:30vw xl:30vw xxl:30vw';
} else {
  mobileBackground = 'https://cdn.outfit7.com/web/campaigns/summer-sports/ttgr/popup/bg-mobile-2.png';
  mobileAlt = 'RUN FOR GOLD';
  desktopBackground = 'https://cdn.outfit7.com/web/campaigns/summer-sports/ttgr/popup/bg-desktop.png';
  desktopAlt = 'RUN FOR GOLD';
  buttonClasses = ['bottom-[6vw]', 'md:bottom-[2vw]', 'md:left-[2vw]', 'w-[62vw]', 'md:w-[20vw]'];
  buttonSrc = 'https://cdn.outfit7.com/web/campaigns/summer-sports/ttgr/popup/play-now.png';
  buttonLink = 'https://o7n.co/mkt-sos-ttgr-ttfweb-popup';
  buttonAlt = 'PLAY NOW';
  buttonSizes = 'xs:62vw sm:62vw md:20vw lg:20vw xl:20vw xxl:20vw';
}
</script>

<style scoped lang="scss">
.floater-link {
  animation: pulse 3s infinite;
}

@keyframes pulse {
  0%, 100% {
    scale: 1;
    animation-timing-function: ease-in-out;
  }
  50% {
    scale: 1.05;
    animation-timing-function: ease-in-out;
  }
}
</style>